<template>
    <admin-default v-slot:admin>
        <div class="application">
            <div class="container container-resize">
                <div class="row border-bottom pb-5">
                    <div class="col-md-9"><h1 class="application__secondary--title">Help</h1></div>
                    <div class="col-6 col-md-3">
                        <h1 class="application__main--title">Welcome,</h1>
                        <h1 class="application__tertiary--title">{{ user.name }}</h1>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-header mt-5">Hi! How can we help?</h1>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="identification" class="form__label">Select Request Type</label>
                            <select class="form-control form__input margin-bottom" id="identification">
                                <option>Enquiry</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="email" class="form__label">Email</label>
                            <input type="email" class="form-control form__input form__input--small" id="email" placeholder="example@gmail.com">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="date" class="form__label">Phone Number</label>
                            <input type="tel" class="form-control form__input margin-bottom" id="date" placeholder="(+234)">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="question" class="form__label">Your Question</label>
                            <textarea class="form-control form__input margin-bottom" id="question" rows="8" placeholder="Write text"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </admin-default>
</template>

<script>
    import AdminDefault from "../navigate/AdminDefault";
    export default {
        name: "Help",
        components: {AdminDefault}
    }
</script>

<style scoped>

</style>